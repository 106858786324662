<template>
    <component v-if="isReady" :is="component" :value="value" v-model="model"></component>
</template>

<script setup>
import {
    defineProps,
    onBeforeMount,
    ref,
    defineModel
} from 'vue';
import useTemplatesCustom from '../services/useTemplatesCustom';

const { getComponent } = useTemplatesCustom();

const props = defineProps({ name: String });
const model = defineModel();
const isReady = ref(false);
const component = ref();

onBeforeMount(async () => {
    component.value = await getComponent(props.name);
    isReady.value = true;
});
</script>
