<template>
    <div>
        <ul>
            <li v-for="(item, index) in list" :key="item.id">
                <div class="input-group mb-1">
                    <div class="input-group-text" id="addon-wrapping">{{ index + 1 }}</div>
                    <input class="form-control flex-grow-1" v-model="item.text" />
                    <div @click="() => deleteItem(index)" class="input-group-text" role="button"><i
                            class="fas fa-minus"></i></div>
                </div>
            </li>
        </ul>
        <span @click="() => list?.push({ text: '', id: Math.random() })" class="d-grid btn btn-sm btn-primary">+</span>
    </div>
</template>

<script setup>
import {
    defineModel,
    defineProps,
    onMounted,
    ref,
    watch,
} from 'vue';

const model = defineModel();
defineProps({
    disabled: { type: Boolean, default: false },
});

const list = ref([{ text: '', id: Math.random() }]);

const deleteItem = (index) => {
    console.log(list.value.splice(index, 1));
};

onMounted(() => {
    console.log('On mounted');
    console.log('model.value', model.value?.length, model.value);
    if (model.value) {
        list.value = model.value?.length ? model.value.split('\n').map((item) => ({ text: `${item.trim()}`, id: Math.random() })) : [{ text: '', id: Math.random() }];
    } else {
        model.value = '';
    }
});

watch(() => list.value, (newList) => {
    console.log('newList', newList);
    model.value = newList.map((i) => `${i.text}`).join('\n');
}, { deep: true });
</script>
