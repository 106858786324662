<template>
    <div class="darmin card">
        <div
            role="button"
            class="card-header pb-0 d-flex align-items-center justify-content-between"
            data-bs-toggle="collapse"
            :data-bs-target="`#card-${ramId}`"
            :aria-expanded="!disabled"
            :aria-controls="`card-${ramId}`"
        >
            <h5>Generar documento con una plantilla</h5>
            <i class="fas fa-angle-up"></i>
        </div>
        <div class="card-body theme-form">
            <div class="collapse show" :id="`card-${ramId}`">
                <div class="row mb-2" v-if="!disabled">
                    <div
                        class="col-lg-8 offset-lg-2 d-flex justify-content-between align-items-center"
                    >
                        <label class="flex-fill align-self-center text-lg-end me-1 mb-0"
                            >Plantilla
                        </label>
                        <div class="flex-fill me-1">
                            <Autocomplete
                                :debounce="1200"
                                @onInput="getExamenTemplates"
                                :items="examenTemplates"
                                :displayItem="
                                    (item) => (typeof item === 'string' ? item : item.name)
                                "
                                placeholder="Buscar un plantilla"
                                @onSelect="onSelectTemplate"
                                ref="templateAutocomplete"
                            ></Autocomplete>
                        </div>
                        <!-- <div class="flex-fill">
                            <button
                                class="btn btn-info"
                                @click="handleSaveAndRender"
                            >
                                Generar
                            </button>
                        </div> -->
                    </div>
                </div>
                <div class="default-according">
                    <div class="card">
                        <div class="card-header pb-0" :id="`#card-${ramId}-heading-level-1`">
                            <h5 class="mb-0">
                                <button
                                    class="accordion-button"
                                    type="btn btn-link"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="`#card-${ramId}-level-1`"
                                    aria-expanded="true"
                                    :aria-controls="`card-${ramId}-heading-level-1`"
                                >
                                    Informacion del resultado
                                </button>
                            </h5>
                        </div>
                        <div class="collapse show" :id="`card-${ramId}-level-1`">
                            <div class="card-body">
                                <div class="row" v-if="templateSelected?.custom">
                                    <CustomTemplatesWrapper :name="templateSelected?.custom" v-model="info"/>
                                </div>
                                <div class="row" v-else>
                                    <template v-for="(eD, i) in templateSelected?.data" :key="i">
                                        <div :class="eD.colSize || getColSize(eD.type)" v-if="eD.auto !== 'true'">
                                            <div class="mb-3">
                                                <label
                                                    class="col-form-label"
                                                    v-if="eD.type !== 'new_line'"
                                                    >{{ eD.title || eD.name }}</label
                                                >
                                                <br v-if="eD.type == 'new_line'" />
                                                <input
                                                    v-if="eD.type == 'String'"
                                                    class="form-control"
                                                    type="text"
                                                    v-model="info[eD.name]"
                                                    :disabled="disabled"
                                                />
                                                <input
                                                    v-if="eD.type == 'Number'"
                                                    class="form-control"
                                                    type="number"
                                                    v-model="info[eD.name]"
                                                    :disabled="disabled"
                                                />
                                                <select
                                                    v-if="eD.type == 'marca_x'"
                                                    class="form-select digits"
                                                    v-model="info[eD.name]"
                                                    :disabled="disabled"
                                                >
                                                    <option value="true">Seleccionado</option>
                                                    <option value="false">Vacio</option>
                                                </select>
                                                <input
                                                    v-if="eD.type == 'Date'"
                                                    class="form-control"
                                                    type="text"
                                                    v-model="info[eD.name]"
                                                    :disabled="disabled"
                                                />
                                                <input
                                                    v-if="eD.type == 'Age'"
                                                    class="form-control"
                                                    type="text"
                                                    v-model="info[eD.name]"
                                                    :disabled="disabled"
                                                />
                                                <textarea
                                                    v-if="eD.type == 'Paragrahp'"
                                                    class="form-control"
                                                    v-model="info[eD.name]"
                                                    :disabled="disabled"
                                                    rows="4"
                                                ></textarea>
                                                <textarea
                                                    v-if="eD.type == 'Paragrahp_optional'"
                                                    class="form-control"
                                                    v-model="info[eD.name]"
                                                    :disabled="disabled"
                                                    rows="4"
                                                ></textarea>
                                                <textarea
                                                    v-if="eD.type == 'List'"
                                                    class="form-control"
                                                    v-model="info[eD.name]"
                                                    :disabled="disabled"
                                                    rows="4"
                                                ></textarea>
                                                <OrderedList
                                                    v-if="eD.type == 'OrderedList'"
                                                    v-model="info[eD.name]"
                                                    :disabled="disabled"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="env === 'development'" class="row">
                    <pre>{{ templateSelected?.data.map(e => ({data: e.name, value: info[e.name]})) }}</pre>
                </div>
                <div class="row" v-if="fileList.length">
                    <div class="files">
                        <VueDraggableNext
                            class="dragArea row w-full"
                            :list="fileList"
                            @change="log"
                        >
                            <div
                                class="col-md-6 col-lg-4 col-xl-3 align-items-center mb-1 p-3"
                                v-for="file in fileList"
                                :key="file.id"
                            >
                                <ResultadoFile
                                    v-model="filesSelected"
                                    :file="file"
                                    :value="file.file.id"
                                    :read-only="false"
                                    :selectable="true"
                                    :removable="false"
                                />
                            </div>
                        </VueDraggableNext>
                    </div>
                </div>
                <div v-if="env === 'development'" class="row">
                    <pre>{{ filesSelected }}</pre>
                </div>
                <div class="row">
                    <div class="col-12 text-end">
                        <div class="btn btn-primary ms-3 mb-1" @click="handleSaveAndRender">
                            <i class="far fa-save"></i>
                            Generar
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { defineComponent, computed, ref, onMounted } from 'vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import { VueDraggableNext } from 'vue-draggable-next';
import useExamenTemplate from '../../ExamenTemplate/services/useExamenTemplate';
import ResultadoFile from './ResultadoFile.vue';
import OrderedList from './inputs/OrderedList.vue';
import CustomTemplatesWrapper from './CustomTemplatesWrapper.vue';

export default defineComponent({
    name: 'ResultadoDocumentData',
    components: {
        Autocomplete,
        VueDraggableNext,
        ResultadoFile,
        OrderedList,
        CustomTemplatesWrapper
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        template: {
            type: Object,
        },
        files: Array,
        copys: Array,
        disabled: Boolean,
        isUpdate: Boolean,
    },
    setup(props, { emit }) {
        const ramId = ref(Math.floor(Math.random() * 1000));
        const checkboxStyle = 'form-check form-check-inline checkbox checkbox-dark mb-0';
        const info = computed({
            get() {
                return props.modelValue;
            },
        });
        const fileList = computed({
            get() {
                return props.files.filter(
                    // eslint-disable-next-line comma-dangle
                    (f) => f.file.mimeType === 'image/jpeg' || f.file.mimeType === 'image/png'
                );
            },
            set(value) {
                emit('update:fileList', value);
            },
        });
        const filesSelected = ref([]);

        const { examenTemplates, getExamenTemplates } = useExamenTemplate();

        const templateSelected = ref();
        const setDataTemplate = (template) => {
            if (template) {
                template.data.map((field) => {
                    // console.log(info);
                    // console.log(info.value[field.name]);
                    info.value[field.name] = info.value[field.name]
                        ? info.value[field.name]
                        : field.default;
                    return true;
                });
                return 0;
            }
            return 0;
        };
        const onMountedSetDataTemplate = (template) => {
            if (template) {
                template.data.map((field) => {
                    // console.log(info);
                    // console.log(info.value[field.name]);
                    if (info.value[field.name]) {
                        info.value[field.name] = info.value[field.name]
                            ? info.value[field.name]
                            : undefined;
                    }
                    return true;
                });
                return 0;
            }
            return 0;
        };

        const getColSize = (type) => {
            switch (type) {
                case 'List':
                case 'Paragrahp':
                case 'Paragrahp_optional':
                case 'new_line':
                    return 'col-lg-12';
                default:
                    return 'col-lg-4';
            }
        };

        const templateAutocomplete = ref(); // autocomplete input

        // const templateSelected = computed(() => {
        //     // console.log('computed', templateAutocomplete.value);
        //     if (templateAutocomplete.value !== undefined) {
        //         templateAutocomplete.value.setText(props.template?.name);
        //     }
        //     return props.template;
        // });

        const onSelectTemplate = (template) => {
            emit('onSelectTemplate', template);
            templateSelected.value = template;
            setDataTemplate(template);
            console.log(templateAutocomplete.value);
            if (templateAutocomplete.value !== undefined) {
                templateAutocomplete.value.setText(templateSelected.value.name);
            }
        };

        onMounted(async () => {
            if (templateAutocomplete.value !== undefined) {
                templateAutocomplete.value.setText(props.template?.name || '');
                templateSelected.value = props.template;
                onMountedSetDataTemplate(props.template);
            }
        });

        const log = () => {
            emit('update:fileList');
            // emit('onUpdate');
        };

        const getUrl = (id) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            `${process.env.VUE_APP_MAIN_SERVICE}/care/examen/resultado/files/view/${id}`;

        const handleSaveAndRender = () => {
            // emit('onSave'); // Ya lo hace
            emit('onRenderDocument', {
                templateId: templateSelected.value.id,
                files: filesSelected.value,
            });
        };

        return {
            info,
            fileList,
            filesSelected,
            log,
            ramId,
            checkboxStyle,
            getUrl,
            getColSize,
            handleSaveAndRender,
            /** Autocompleters */
            examenTemplates,
            getExamenTemplates,
            templateAutocomplete,
            templateSelected,
            onSelectTemplate,
            env: process.env.NODE_ENV,
        };
    },
});
</script>

<style scoped>
.col-form-label {
    text-transform: capitalize;
}

.files .file {
    /* cursor: pointer; */
    border: 1px solid #efefef;
    border-radius: 5px;
    background-color: #fdfdfd;
}
.files .file:hover {
    background-color: #efefef;
    border-color: #fdfdfd;
}
.files .title {
    margin: 0;
}
.files i {
    cursor: pointer;
}
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}
.modal__title {
    font-size: 1.5rem;
    font-weight: 700;
}
.dark-mode div::v-deep .modal-content {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style>
